import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import { themeStyles, colors, presets, metrics } from '../utils/theme'
import _ from 'lodash'
import PropTypes from "prop-types"
import TextLoop from "react-text-loop"
import Button from '../components/Button'
import Promo from '../components/Promo'
import Utah from '../assets/utah.png'
import FeatureTable from '../components/FeatureTable'
import withConfig from '../wrappers/withConfig'
import Reviews from '../components/Reviews'
import ImageSlider from '../components/ImageSlider'
import MainHelmet from '../components/MainHelmet'

const styles = {
  heroTextContainer: {
    color: colors.white,
    textAlign: 'left',
    fontSize: rhythm(1),
    fontWeight: 800,
    paddingTop: rhythm(3),
    paddingBottom: rhythm(3),
    paddingLeft: metrics.defaultMobilePadding,
    paddingRight: metrics.defaultMobilePadding,
    [presets.Mobile]: {
      paddingTop: rhythm(5),
      paddingBottom: rhythm(5),
      fontSize: rhythm(1.5),
    },
    [presets.Tablet]: {
      paddingLeft: metrics.defaultPadding,
      paddingRight: metrics.defaultPadding,
      paddingTop: rhythm(7),
      paddingBottom: rhythm(7),
      fontSize: rhythm(2.5),
    },
  },
  divider: {
    width: '100%',
    maxWidth: rhythm(10),
    borderTop: `5px solid ${colors.gold}`,
    margin: `${rhythm(2)} 0`,
  },
  handle: {
    fontSize: rhythm(1.5),
    fontSize: rhythm(1),
    [presets.Mobile]: {
      fontSize: rhythm(1.5),
    },
    [presets.Tablet]: {
      fontSize: rhythm(2.5),
    },
    fontColor: colors.lightGrey,
  },
  halfRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [presets.Tablet]: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'row',
    }
  },
  sliderHolder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100vw',
    [presets.Tablet]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    }
  }
}


const IndexPage = ({
  noMoreOptions,
  businessDescription,
  featureList1Headline,
  featureList1Subtext,
  featureList1Options,
  promoTagline,
  showIndustry,
  project,
  headline,
  ...otherProps
 }) => {
  const headlinePhoto = headline.childImageSharp.fixed.src
  const isCarpet = project === 'CARPET'

  return (
    <Layout>
      <MainHelmet
        title = "Best Solutions for Epoxy Floor, Window, Carpet & Corporate Cleaning | Blumont Services"
        description = "Explore our top notch industrial cleaning solutions. Specializing in epoxy floor, window washing, carpet maintenance, and more for businesses in American Fork, UT."
        keywords = "Epoxy floor maintenance,american fork cleaning,Professional window washing,Corporate landscaping services,Pressure washing for businesses,Exterior pressure washing for offices,Corporate lawn maintenance,Industrial cleaning solutions,Carpet cleaning for businesses,Commercial carpet maintenance UT,Commercial carpet maintenance,commercial cleaning janitorial services,janitorial service syracuse"
      />
      <div css={{ backgroundColor: colors.brightBlue, backgroundImage: `url(${headlinePhoto})`, backgroundSize: 'cover', backgroundPosition: 'center right' }}>
        <div css={[themeStyles.contentWidth]}>
          <div css={styles.heroTextContainer}>
            <span>No More</span>
            <br />
            <TextLoop interval={2000}>
              {_.map(noMoreOptions, option => <span key={option.text}>{option.text}</span>)}
            </TextLoop>
            <div css={styles.divider}/>
            <div css={styles.handle}>
              We'll handle it.
            </div>
            <Button
              text="Get in Touch"
              css={{marginTop: metrics.defaultMargin}}
              to='/contact'
            />
          </div>
        </div>
      </div>
      <div css={{ backgroundColor: colors.lightGrey }}>
        <div css={themeStyles.contentWidth}>
          <div css={styles.sliderHolder}>
          <div css={{ width: '100%', [presets.Mobile]: {maxWidth: rhythm(20)}, [presets.Tablet]: {maxWidth: rhythm(130)}, [presets.Desktop]: {maxWidth: rhythm(60)}, [presets.Hd]: {maxWidth: rhythm(40)}}}>
            <ImageSlider project={project} />
          </div>
            <div css={themeStyles.textPadding}>
              <h2>We are world-class. <span css={{borderBottom: `5px solid ${colors.gold}`}}>Guaranteed.</span></h2>
              <p>
                {businessDescription}
              </p>
              <p>
                We back our services by the <strong>BluMont Quality Service Guarantee</strong>:
                <br/>All services will be performed as promised at a professional level of quality or the cost of the day’s cleaning is on us!
              </p>
              <p><strong>It is that simple.</strong></p>
            </div>
          </div>
        </div>
      </div>
      <div css={[themeStyles.contentWidth, themeStyles.elementMargin]}>
        <div css={themeStyles.textPadding}>
          <div css={styles.halfRow}>
            <img alt='Utah' src={Utah} css={{maxHeight: rhythm(10), marginRight: rhythm(2)}}/>
            <div>
              <h2>Utah is our business.</h2>
              <p>
                Along with the Greater Salt Lake Area, we service clients in surrounding counties, including Utah, Davis, Weber, and Wasatch – from as far north as Logan to as far south as St. George.
              </p>
            </div>
          </div>
        </div>
      </div>
      {!isCarpet && <div css={[themeStyles.contentWidth, {backgroundColor: colors.lightGrey}, themeStyles.elementMargin]}>
        <div css={{display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '800px', margin: '0 auto', paddingTop: '3em' }}>
          <div css={{ position: 'relative', overflow: 'hidden', paddingTop: '56.25%', width: '100%'}}>
            <iframe
              css={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0, }}
              src='https://www.youtube.com/embed/s_Lbfj9BxBw' frameBorder="0"
              modestbranding
              nobranding
              controls={0}
            >
            </iframe>
          </div>
        </div>
      </div>
      }
      <div css={[themeStyles.contentWidth, {backgroundColor: colors.lightGrey}, themeStyles.elementMargin, { marginTop: 0, paddingTop: '3em' }]}>
        <div css={themeStyles.textPadding}>
          <div css={styles.halfRow}>
            <div css={themeStyles.innerTextBreak}>
              <h2>{featureList1Headline}</h2>
              <p>
                {featureList1Subtext}
              </p>
              <p>
                Here are some of our most popular services.
                <br /> Get in contact for a <strong>free quote</strong> to see what we can do for your business.
              </p>
              <Button text='Get My Free Quote' to='/contact' style={{marginBottom: rhythm(1)}}/>
            </div>
            <div css={themeStyles.innerTextBreak}>
              <FeatureTable
                rows={_.map(featureList1Options, option => option.text)}
              />
            </div>
          </div>
        </div>
      </div>
      <Promo tagline={promoTagline}/>
      <Reviews />
      {showIndustry &&
        <div css={[themeStyles.contentWidth, {backgroundColor: colors.lightGrey}]}>
          <div css={themeStyles.textPadding}>
            <div css={styles.halfRow}>
              <div css={themeStyles.innerTextBreak}>
                <h2>We know your industry.</h2>
                <p>
                  Your industry has unique cleaning and building needs. We're ready for them.
                </p>
                <p>
                  With years for experience across all business types, there's nothing we haven't seen. Bring us your hard problems and we'll find simple solutions.
                </p>
                <Button text="Let's Chat" to='/contact' style={{marginBottom: rhythm(1)}}/>
              </div>
              <div css={themeStyles.innerTextBreak}>
                <FeatureTable
                  rows={[
                    'Commercial',
                    'Retail',
                    'Warehouse',
                    'Manufacturing',
                    'Healthcare',
                    'Educational',
                    'Hospitality',
                    'Events',
                    'Food Service',
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </Layout>
  )
}

const OuterComponent = withConfig(IndexPage)

export default OuterComponent
