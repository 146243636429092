import React, { Component } from 'react'
import { rhythm } from '../utils/typography'
import { themeStyles, colors } from '../utils/theme'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...themeStyles.innerTextBreak,
    margin: '0 auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: rhythm(0.5),
    backgroundColor: 'rgba(0,0,0,.1)',
    ':nth-child(2n)': {
      backgroundColor: 'rgba(0,0,0,0)',
    }
  },
  icon: {
    marginRight: '10px',
    minWidth: '35px',
  }
}

class FeatureTable extends Component {
  render() {
    const rows = this.props.rows.map(row =>
      <div css={styles.row}>
        <IoIosCheckmarkCircleOutline color={colors.gold} size={30} style={styles.icon}/>{row}
      </div>
    )
    return (
      <div css={styles.container}>
        {rows}
      </div>
    )
  }
}

export default FeatureTable
